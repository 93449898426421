<template>
  <valorem-modal
    id="modal-detalhes"
    ref="modal"
    :titulo-modal="$t('AUDITORIA.DETALHES')"
    titulo-ok="..."
    ok-desabilitado
  >
    <div class="row">
      <input-text
        class="col-12 col-md-3"
        label="Usuario"
        v-model="dados.usuarioNome"
        disabled
      />
      <input-date
        class="col-12 col-md-3"
        label="Data de modificação"
        v-model="dados.dataCriacao"
        disabled
      />
      <input-text
        class="col-12 col-md-3"
        label="Tipo da modificação"
        v-model="dados.tipo"
        disabled
      />
      <input-text
        class="col-12 col-md-3"
        label="Entidade"
        v-model="dados.nomeTabela"
        disabled
      />

      <valorem-tabela
        class="col-12"
        sem-selecionar-todos
        v-model="dados.propriedades"
        :colunas="colunas"
      />
    </div>
  </valorem-modal>
</template>
<script>
import { InputText, InputDate } from "@/components/inputs";
export default {
  components: {
    InputText,
    InputDate,
  },
  data() {
    return {
      dados: {},
      colunas: [
        {
          key: "nomeDaPropriedade",
          label: this.$t("AUDITORIA.CAMPO"),
        },
        {
          key: "valorAntigo",
          label: this.$t("AUDITORIA.VALOR_ANTIGO"),
        },
        {
          key: "valorNovo",
          label: this.$t("AUDITORIA.VALOR_NOVO"),
        },
      ],
    };
  },
  methods: {
    abrirModal: function (dados) {
      this.dados = dados;
      this.$refs.modal.abrirModal();
    },
    fecharModal: function () {
      this.$refs.modal.fecharModal();
    },
  },
  computed: {},
};
</script>
<style lang=""></style>
