<template>
  <div>
    <b-card>
      <div>
        <h4 class="card-title">{{ tabela.quantidadeItens }} registros</h4>
      </div>
      <div class="filtro-valorem d-flex align-items-center mb-2">
        <b-button id="btn-abrir-filtro" variant="valorem-ciano" @click="abrirFiltro">
          Filtrar
        </b-button>
        <b-button
          id="btn-visualizar"
          @click="visualizar(null)"
          v-if="dadosSelecionados.length == 1"
          class="ml-2"
          variant="outline-primary"
        >
          {{ $t("GERAL.VISUALIZAR") }}
        </b-button>
        <modal-filtro ref="filtro" @filtrar="filtrar" />
        <modal-detalhes ref="detalhes" />
      </div>
      <valorem-tabela
        id="tabela"
        sem-selecionar-todos
        :metodo-validacao-selecionar="metodoValidacaoSelecionar"
        v-model="tabela.dados"
        :colunas="tabela.colunas"
        :quantidade-itens="tabela.quantidadeItens"
        ref="tabela"
        backend-paginado
        @paginar="listar"
      >
        <template #cell(ativo)="dados">
          <span :class="`text-${retornarVariante(dados.value).variante} text-capitalize`">
            {{ retornarVariante(dados.value).text }}
          </span>
        </template>
      </valorem-tabela>
    </b-card>
  </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import AuditoriaService from "@/common/services/auditoria/auditoria.service";
import helpers from "../../common/utils/helpers";
import ModalFiltro from "./components/ModalFiltro.vue";
import ModalDetalhes from "./components/ModalDetalhes.vue";
export default {
  components: { ModalFiltro, ModalDetalhes },
  data: function () {
    return {
      filtro: {
        dataDe: null,
        dataAte: null,
        tipoAuditoria: null,
        entidade: null,
        campo: null,
        valor: null,
        usuarioId: null,
      },
      tabela: {
        dados: [],
        colunas: [
          { key: "seletor", label: "" },
          {
            key: "dataCriacao",
            label: this.$t("AUDITORIA.DATA_CRIACAO"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, true);
            },
          },
          {
            key: "tipo",
            label: this.$t("AUDITORIA.TIPO_AUDITORIA"),
          },
          {
            key: "nomeTabela",
            label: this.$t("AUDITORIA.ENTIDADE"),
          },
          {
            key: "usuarioNome",
            label: this.$t("AUDITORIA.NOME_USUARIO"),
          },
        ],
        quantidadeItens: 0,
        paginaAtual: 0,
        porPagina: 0,
      },
      botaoAtivarInativar: null,
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("AUDITORIA.TITULO_LISTA") },
    ]);
    this.listar();
  },
  methods: {
    abrirFiltro: function () {
      this.$refs.filtro.abrirModal(this.filtro);
    },
    filtrar: function (filtro) {
      this.filtro = filtro;
      this.$refs.tabela.limpar();
      this.listar();
    },
    listar: function (paginaAtual = 0, porPagina = 10) {
      this.$store.dispatch(START_LOADING);
      AuditoriaService.listar(this.filtro, paginaAtual, porPagina)
        .then((res) => {
          this.tabela.dados = res.data.data.itens;
          this.paginaAtual = paginaAtual;
          this.porPagina = porPagina;
          this.tabela.quantidadeItens = res.data.data.paginacao.totalDeElementos;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    visualizar: function (item) {
      if (item) {
        this.$refs.detalhes.abrirModal(item);
      } else {
        this.$refs.detalhes.abrirModal(this.dadosSelecionados[0]);
      }
    },
    metodoValidacaoSelecionar: function () {
      return true;
    },
  },
  computed: {
    dadosSelecionados: function () {
      return this.tabela.dados.filter((el) => {
        return el.selecionado;
      });
    },
    permissao: function () {
      return helpers.validarAcesso("Produto");
    },
  },
  watch: {
    dadosSelecionados: function () {
      if (this.dadosSelecionados.length == 1) {
        this.botaoAtivarInativar = this.dadosSelecionados[0].ativo;
      } else if (this.dadosSelecionados.length == 0) {
        this.botaoAtivarInativar = null;
      }
    },
  },
};
</script>
<style lang=""></style>
