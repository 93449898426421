<template>
  <valorem-modal
    id="modal-filtro"
    ref="modal"
    :titulo-modal="$t('AUDITORIA.FILTRO')"
    @ok="filtrar"
    titulo-ok="Filtrar"
  >
    <div class="row">
      <input-date
        id="input-data-de"
        class="col-12 col-md-6"
        :label="$t('AUDITORIA.DE')"
        v-model="form.dataDe"
      />
      <input-date
        id="input-data-ate"
        class="col-12 col-md-6"
        :label="$t('AUDITORIA.ATE')"
        v-model="form.dataAte"
      />
      <input-select
        id="input-tipo"
        class="col-12 col-md-6"
        :label="$t('AUDITORIA.TIPO')"
        v-model="form.tipoAuditoria"
        :options="opcoes.tiposAuditoria"
      />
      <input-select
        id="input-entidade"
        class="col-12 col-md-6"
        :label="$t('AUDITORIA.ENTIDADE')"
        v-model="form.entidade"
        :options="opcoes.entidades"
      />
      <input-select
        id="input-campo"
        class="col-12 col-md-6"
        :label="$t('AUDITORIA.CAMPO')"
        v-model="form.campo"
        :options="opcoes.campos"
      />
      <input-text
        id="input-valor"
        class="col-12 col-md-6"
        :label="$t('AUDITORIA.VALOR')"
        v-model="form.valor"
      />
      <input-autocomplete
        id="input-usuario"
        class="col-12"
        :label="$t('AUDITORIA.NOME_USUARIO')"
        v-model="form.usuarioId"
        :options="opcoes.usuarios"
      />
    </div>
  </valorem-modal>
</template>
<script>
import AuditoriaService from "@/common/services/auditoria/auditoria.service";
import UsuarioService from "@/common/services/usuario/usuario.service";
import {
  InputDate,
  InputSelect,
  InputAutocomplete,
  InputText,
} from "@/components/inputs";
export default {
  components: {
    InputDate,
    InputSelect,
    InputAutocomplete,
    InputText,
  },
  data() {
    return {
      form: {},
      opcoes: {
        tiposAuditoria: [
          { value: null, text: this.$t("AUDITORIA.SELECIONE") },
          { value: "Adicionado", text: this.$t("AUDITORIA.ADICIONADO") },
          { value: "Modificado", text: this.$t("AUDITORIA.MODIFICADO") },
          { value: "Excluido", text: this.$t("AUDITORIA.DELETADO") },
        ],
        entidades: [{ value: null, text: this.$t("AUDITORIA.SELECIONE") }],
        campos: [{ value: null, text: this.$t("AUDITORIA.SELECIONE") }],
        usuarios: [{ value: null, text: this.$t("AUDITORIA.SELECIONE") }],
      },
    };
  },
  mounted() {
    this.buscarEntidades();
    this.buscarUsuarios();
  },
  methods: {
    abrirModal: function (form) {
      this.form = form;
      this.$refs.modal.abrirModal();
    },
    fecharModal: function () {
      this.$refs.modal.fecharModal();
    },
    filtrar: function () {
      this.$emit("filtrar", this.form);
    },
    buscarUsuarios: function () {
      UsuarioService.listar(null, 0, 0, true).then((res) => {
        this.opcoes.usuarios = [
          { value: null, text: this.$t("AUDITORIA.SELECIONE") },
          ...res.data.data.itens.map((el) => {
            return {
              value: el.id,
              text: el.nome,
            };
          }),
        ];
      });
    },
    buscarEntidades: function () {
      AuditoriaService.obterEntidades(this.form).then((res) => {
        this.opcoes.entidades = [
          { value: null, text: this.$t("AUDITORIA.SELECIONE") },
          ...res.data.data.entidades.map((el) => {
            return {
              value: el,
              text: el,
            };
          }),
        ];

        this.opcoes.campos = [];
      });
    },
    buscarCampos: function () {
      if (!this.form.entidade) {
        this.opcoes.campos = [];
        return;
      }
      AuditoriaService.obterCampos(this.form.entidade).then((res) => {
        this.opcoes.campos = [
          { value: null, text: this.$t("AUDITORIA.SELECIONE") },
          ...res.data.data.campos
            .map((el) => {
              return {
                value: el,
                text: el,
              };
            })
            .filter(
              (el) => el.value != "ConcurrencyStamp" && el.value != "AccessFailedCount"
            ),
        ];
      });
    },
  },
  computed: {},
  watch: {
    "form.entidade": function () {
      this.buscarCampos();
    },
  },
};
</script>
<style lang=""></style>
