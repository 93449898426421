import ApiService from "@/common/api/api.service";

const AuditoriaService = {
  listar : function(filtro, paginacao = 0, porPagina = 0,){
    let link = `auditoria?NumeroDaPagina=${paginacao}&TamanhoDaPagina=${porPagina}`;
    link = link.concat(`${filtro.dataDe ? `&dataDe=${filtro.dataDe}` : ''}`);
    link = link.concat(`${filtro.dataAte ? `&dataAte=${filtro.dataAte}` : ''}`);
    link = link.concat(`${filtro.tipoAuditoria ? `&tipoAuditoria=${filtro.tipoAuditoria}` : ''}`);
    link = link.concat(`${filtro.entidade ? `&entidade=${filtro.entidade}` : ''}`);
    link = link.concat(`${filtro.campo ? `&campo=${filtro.campo}` : ''}`);
    link = link.concat(`${filtro.valor ? `&valor=${filtro.valor}` : ''}`);
    link = link.concat(`${filtro.usuarioId ? `&usuarioId=${filtro.usuarioId}` : ''}`);
    return ApiService.get(link);
  },
  obterEntidades : function(){
    return ApiService.get(`auditoria/entidades-dropdown`);
  },
  obterCampos : function(entidade){
    return ApiService.get(`auditoria/campos-dropdown?entidade=${entidade}`);
  },
};

export default AuditoriaService;
